<template>
  <div>
    <Loading v-show="show" />
    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text breadcrumb-text1">
              <h1 style="text-align: center">{{ FacultyMembers }}</h1>
              <!-- <div class="breadcrumb-bar">
                <ul class="breadcrumb">
                  <li>
                    <router-link to="/"> Fac </router-link>
                  </li>
                  <li > Department </li>
                </ul>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Course Details Area Start-->
    <div class="course-details-area section-padding">
      <div class="container">
        <div class="row">
          <div
            v-for="member in Members"
            :key="member.postId"
            class="col-lg-4 col-md-12 col-12"
          >
            <div class="sidebar-widget">
              <div class="single-sidebar-widget">
                <div class="tution-wrapper">
                  <div class="tutor-image">
                    <img
                      style="width: 150px"
                      loading="lazy"
                      :src="
                        'https://api2.yuniv.net:444/images/post/' +
                        member.postImage
                      "
                      alt=""
                    />
                  </div>
                  <div class="single-teacher-text">
                    <h3>
                      <router-link
                        href="#"
                        aria-label="title"
                        :to="{
                          name: 'FaculityMembersDetails',
                          params: {
                            BrnId: member.brnID,
                            id: member.postID,
                          },
                        }"
                        >{{ member.postTitle }}</router-link
                      >
                    </h3>
                    <h4>{{ member.specialization }}</h4>
                    <p>
                      {{ member.degree }}
                      <!-- <div class="social-links">
                      <a href="#"><i class="fa fa-facebook"></i></a>
                      <a href="#"><i class="fa fa-twitter"></i></a>
                      <a href="#"><i class="fa fa-google"></i></a>
                      <a href="#"><i class="fa fa-instagram"></i></a>
                    </div> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End of Course Details Area-->
  </div>
</template>

<script>
import Loading from "./Loading";
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
      Members: [],
      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      FacultyMembers: "",
    };
  },
  methods: {
    getApis() {
      var self = this;
      var ID = this.$route.params.BrnId;
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostDetails");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", ID);
      bodyFormData.append("Type", "Univ.Faculty.Members");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      }).then(function (response) {
        self.Members = response.data;
        setTimeout(() => {
          self.show = false;
          document.querySelectorAll("div.loader").forEach(function (element) {
            element.setAttribute("hidden", true);
          });
        }, 100);
      });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;

    if (localStorage.getItem("lang") == "Ar") {
      self.translate = self.ArTranslat;
      document.getElementById("langfilewebsites")?.remove();
    } else {
      self.translate = self.EnTranslat;
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "../../style-ltr.css");
      document.head.appendChild(link1);
    }
    self.FacultyMembers = self.translate[0]["Home"]["FacultyMembers"];
    self.getApis();
  },
};
</script>
